/* Constantes */
:root {
  --background-color: #FFF;
  --on-background-color: #000000;
  --primary-color: #FFF;
  --on-primary-color: #31b0e6;
  --secondary-color: #2bb5ef;
  --on-secondary-color: #FFF;
  --list-item: #ffffffdb;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
}

#root {

  height: 100%;
}

.App-Container {
  /* Position
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 60px; */
  width: 100%;
  height: 100%;

  /* Background */
  background-image: url(https://isipay.fr/wp-content/uploads/2021/06/fondssolo-scaled.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: local;
}

.mdc-button__ripple {
  background-color: var(--on-primary-color);
}

.mdc-button__label {
  color: white;
}

.Container-Display-None {
  display: none !important;
}

p {
  margin: 0;
}

.gm-style-iw-chr {
  height: 0px;
}
