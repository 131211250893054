.Header-Container {
  background-color: #EFEEEC;
  height: 60px;
}

.Header-EmptyContainer {
  width: 48px;
}

.Header-Logo {
  object-fit: contain;
  height: 60%;
}

.Header-SectionContainer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%
}
