.Map-Container {
  width: "100%";
  height: "100%";
}

.Map-SelectBorne {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  width: 100%;
}

.Map-SelectBorne-Container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Map-Tooltip-Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 35px;
  width: 220px;
}

.Map-Tooltip-Icon {
  color: grey;
  width: 32;
  height: 32;
}


@media(max-width:800px) {
  .Map-SelectBorne {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3.5rem;
  }
}

.Map-Selector {
  width: 50%;
}

.Map-Tooltip-Description {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Map-Tooltip-Services {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}
