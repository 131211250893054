.TimeSelector-Container {
  align-items: center;
  display:flex;
  height:100%;
  margin-left: auto;
}

.TimeSelector-TextField {
  margin-left: 10px;
  margin-right: 10px;
}

.TimeSelector-TextField > input {
  color: black !important;
}
