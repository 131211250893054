.Login-Button-Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.Login-Container {
  /* Position */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* Taille */
  height: 95%;
  gap: 2.5rem;
}

.Login-Link {
  text-decoration: underline;
  color: cadetblue;
  cursor: pointer;
}

.Login-Logo {
  position: absolute;
  top: 75px;
  width: 300px;
}

.Login-Title {
  color: #31b0e6;
}