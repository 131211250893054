.DescriptifBorne-Container {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.DescriptifBorne-Image {
  border-radius: 30px;
  height: 100px;
  width: 100px;

  cursor: pointer;
}


.DescriptifBorne-Image-Container {
    margin: 10px auto 10px;
    width: 350px;
}
/* .DescriptifBorne-Image-Container {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  width: 70%;
} */

.DescriptifBorne-Text {
  padding-top: 1rem;
  width: 85%;
}

.slick-slide > div { margin: 0 5px; }
