.Achat-ButtonContainer {
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 0px;
  /* position: absolute; SH 2023.07.28*/
  right: 0px;
  position: fixed;
  /* SH 2023.07.28 suite soucis emplacement bouton valider site ogliss*/
}

.Achat-Container {
  display: flex;
  flex-direction: column;
}

.Achat-TextField {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.Achat-TitleContainer {
  display: flex;
  font-size: 28px;
  justify-content: center;
}