.CreateAccount-Link {
  cursor: pointer;
}

.CreateAccount-Button-Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.CreateAccount-ConfirmPassword-Label {
  font-size: small;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.CreateAccount-Container {
  /* Position */
  align-items: center;
  display: flex;
  flex-direction: column;

  /* Taille */
  height: 100%;
  gap: 2rem;
}

/* Gestion des mots de passe */
.CreateAccount-ForcePassword-Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CreateAccount-ForcePassword-Label {
  display: flex;
  flex-direction: row;
}

.CreateAccount-ForcePassword-Strength[data-strength="0"] {
  color: red;
}

.CreateAccount-ForcePassword-Strength[data-strength="1"] {
  color: orangered;
}

.CreateAccount-ForcePassword-Strength[data-strength="2"] {
  color: orange;
}

.CreateAccount-ForcePassword-Strength[data-strength="3"] {
  color: yellowgreen;
}

.CreateAccount-ForcePassword-Strength[data-strength="4"] {
  color: green;
}



.CreateAccount-Input {
  width: 250px;
  height: 2.5rem;
}

.CreateAccount-Input-Container {
  align-items: center;
  background-color: #ffffffcf;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 10px;
}

.CreateAccount-InfoSecurityContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CreateAccount-RulesContainer {
  font-size: small;
  margin-top: 1rem;
}

.CreateAccount-RulesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CreateAccount-Title {
  font-size: 24px;
  font-weight: 600;
}
