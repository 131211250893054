.HistoAchats-Container {
  /* Position */
  align-items: center;
  display: flex;
  flex-direction: column;
}

.HistoAchats-Title {
  font-family: sans-serif;
}

.HistoAchats-Item-Container {
  border-radius: 12px;
  border: var(--on-primary-color) 2px solid;
  box-shadow: 5px 10px 10px rgba(94, 94, 94, 0.199);
  margin: 1rem;
  padding: 1rem;
  width: 70vw;
}

.HistoAchats-Item-Title {
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.HistoAchats-Item-Service {
  display: flex;
  flex: 10;
  flex-direction: row;
  width: 100%;
}

.HistoAchats-Item-Service-Info {
  display: flex;
  width: 100%;
  /* justify-content: flex-end; */
}

.HistoAchats-Item-Services {
  display: flex;
  flex-direction: column;
}

.HistoAchats-Item-Service-Quantite {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.HistoAchats-Item-Service-Libelle {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.HistoAchats-Item-Price {
  font-weight: bold;
  color: black;
}

.HistoAchats-List-Header {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: bold;
}

.HistoAchats-List-Item {
  background-color: var(--list-item);
}